import Bulma from '@vizuaalog/bulmajs';

window.window_reload_eval_doc_ui = function window_reload_eval_doc_ui(user_id, item_id) {
  var url = '/documento-evaluacion/listado/' + user_id + '/' + item_id;
  $.get(url, function(data) {
    $('#eval-doc-file-list-' + item_id).html(data);
  });
}

$(document).on('change', '.evaluation-document-file-select', function() {
  $('#eval-doc-upload-file-form').submit()
});


$(document).on('click', '.eval-doc-delete', function() {
  var file_id = $(this).data('evaluation-document-id');
  $.post( "/documento-evaluacion/elimina", { id: file_id })
    .done(function( data ) {
      $('#eval-doc-' + file_id).remove()
    
      Bulma.create('notification', {
        isDismissable: true,
        dismissInterval: 4000,
        color: 'success',
        body: 'Archivo eliminado.',
        parent: document.getElementById('notification-area'),
       }).show();

    });
});