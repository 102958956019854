import Bulma from '@vizuaalog/bulmajs';

$(document).on('click', '#load_all_id', function() {
    const person_id = $(this).data('person_id');
    const email = $(this).data('email');
    $.get("/pnpc_reports/projects/" + person_id , function(data) {
        const code = ' </br><h1 class="pnpc-report-title">Proyectos</h1> ' + data;
        $('#pnpc_workarea_20').html(code);
    });
    $.get("/pnpc_reports/journal_articles/" + person_id + ".html", function(data) {
        const code = ' </br><h1 class="pnpc-report-title">Artículos en revistas</h1>' + data;
        $('#pnpc_workarea_1').html(code);
    });
    $.get("/pnpc_reports/journal_conferences/" + person_id + ".html", function(data) {
        const code =  ' </br><h1 class="pnpc-report-title">Artículos en conferencias </h1> ' + data;
        $('#pnpc_workarea_2').html(code);
    });
    $.get("/pnpc_reports/popular_sciences/" + person_id + ".html", function(data) {
        const code = ' </br><h1 class="pnpc-report-title">Difusión y Divulgación</h1> ' + data;
        $('#pnpc_workarea_3').html(code);
    });
    $.get("/pnpc_reports/books/" + person_id + ".html", function(data) {
        const code =' </br><h1 class="pnpc-report-title">Libros y Capítulos</h1> ' + data;
        $('#pnpc_workarea_4').html(code);
    });
    $.get("/pnpc_reports/conference_works/" + person_id + ".html", function(data) {
        const code = ' </br><h1 class="pnpc-report-title">Trabajos en Conferencias</h1> ' + data;
        $('#pnpc_workarea_5').html(code);
    });
    $.get("/pnpc_reports/patents/" + person_id + ".html", function(data) {
        const code = ' </br><h1 class="pnpc-report-title">Patentes</h1> ' + data;
        $('#pnpc_workarea_6').html(code);
    });
    $.get("/pnpc_reports/conferences/" + person_id + ".html", function(data) {
        const code = ' </br><h1 class="pnpc-report-title">Conferencias</h1> ' + data;
        $('#pnpc_workarea_7').html(code);
    });
    $.get("/pnpc_reports/associations/" + person_id + ".html", function(data) {
        const code = ' </br><h1 class="pnpc-report-title">Asociaciones y Redes</h1> ' + data;
        $('#pnpc_workarea_8').html(code);
    });
    $.get("/pnpc_reports/awards/" + person_id + ".html", function(data) {
        const code = ' </br><h1 class="pnpc-report-title">Reconocimientos</h1> ' + data;
        $('#pnpc_workarea_9').html(code);
    });
    $.get("/pnpc_reports/event_organizers/" + person_id + ".html", function(data) {
        const code = ' </br><h1 class="pnpc-report-title">Organizador de Eventos</h1> ' + data;
        $('#pnpc_workarea_10').html(code);
    });
    $.get("/pnpc_reports/project_reviewers/" + person_id + ".html", function(data) {
        const code = ' </br><h1 class="pnpc-report-title">Evaluador de Proyectos</h1> ' + data;
        $('#pnpc_workarea_11').html(code);
    });
    $.get("/pnpc_reports/journal_article_reviewers/" + person_id + ".html", function(data) {
        const code = ' </br><h1 class="pnpc-report-title">Evaluador de Artículos</h1> ' + data;
        $('#pnpc_workarea_12').html(code);
    });
    $.get("/pnpc_reports/relevant_activities/" + person_id + ".html", function(data) {
        const code = ' </br><h1 class="pnpc-report-title">Actividades Relevantes</h1> ' + data;
        $('#pnpc_workarea_13').html(code);
    });
    $.get("/pnpc_reports/internal_projects/" + person_id + ".html", function(data) {
        const code = ' </br><h1 class="pnpc-report-title">Proyectos Internos (convocatorias)</h1> ' + data;
        $('#pnpc_workarea_14').html(code);
    });
    $.get("/pnpc_reports/advisor/" + email , function(data) {
        const code = ' </br><h1 class="pnpc-report-title">Alumnos Activos</h1> ' + data;
        $('#pnpc_workarea_15').html(code);
    });
    $.get("/pnpc_reports/synodal/" + email , function(data) {
        const code = ' </br><h1 class="pnpc-report-title">Sinodal</h1> ' + data;
        $('#pnpc_workarea_16').html(code);
    });
    $.get("/pnpc_reports/residents/" + email , function(data) {
        const code = ' </br><h1 class="pnpc-report-title">Residentes</h1> ' + data;
        $('#pnpc_workarea_17').html(code);
    });
    $.get("/pnpc_reports/courses/" + email , function(data) {
        const code = ' </br><h1 class="pnpc-report-title">Cursos</h1> ' + data;
        $('#pnpc_workarea_18').html(code);
    });
    $.get("/pnpc_reports/services/" + email , function(data) {
        const code = ' </br><h1 class="pnpc-report-title">Servicios</h1> ' + data;
        $('#pnpc_workarea_19').html(code);
    });
});

$(document).on('change', '#select_pnpc_report_id', function() {
    const rpt_id = this.options[this.selectedIndex].value;
    const person_id = $(this).data('person_id');

    let _url = '';
    switch(parseInt(rpt_id)) {
        case 1:
            _url = "/pnpc_reports/journal_articles/" + person_id + ".html"; //
            break;
        case 2:
            _url = "/pnpc_reports/journal_conferences/" + person_id + ".html"; //
            break;
        case 3:
            _url = "/pnpc_reports/popular_sciences/" + person_id + ".html"; //
            break;
        case 4:
            _url = "/pnpc_reports/books/" + person_id + ".html"; //
            break;
        case 5:
            _url = "/pnpc_reports/conference_works/" + person_id + ".html"; //
            break;
        case 6:
            _url = "/pnpc_reports/patents/" + person_id + ".html"; //
            break;
        case 7:
            _url = "/pnpc_reports/conferences/" + person_id + ".html"; //
            break;
        case 8:
            _url = "/pnpc_reports/associations/" + person_id + ".html"; //
            break;
        case 9:
            _url = "/pnpc_reports/awards/" + person_id + ".html";
            break;
        case 10:
            _url = "/pnpc_reports/event_organizers/" + person_id + ".html";
            break;
        case 11:
            _url = "/pnpc_reports/project_reviewers/" + person_id + ".html";
            break;
        case 12:
            _url = "/pnpc_reports/journal_article_reviewers/" + person_id + ".html";
            break;
        case 13:
            _url = "/pnpc_reports/relevant_activities/" + person_id + ".html";
            break;
        case 14:
            _url = "/pnpc_reports/internal_projects/" + person_id + ".html";
            break;
        default:
            _url = "/pnpc_reports/journal_articles/" + person_id + ".html"
    };

    $.get(_url, function(data) {
        $('#pnpc_workarea').html(data);
    });

});