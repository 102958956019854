import Bulma from '@vizuaalog/bulmajs';

$(document).on('change', '#ui_cite_type', function() {
  var t = $(this).val();

  if (t == 'simple') {
    $('.cite-simple').show();
    $('.cite-extended').hide();
  } else {
    $('.cite-simple').hide();
    $('.cite-extended').show();
  }
});

$(document).on('click', '.get-cites', function() {
  var product_class = $(this).data('product-class');
  var product_id = $(this).data('product-id');
  var url = '/citas/ui/' + product_class + '/' + product_id
  $.get(url, function(data) {
    $("#cites-workarea").html(data);
  });
})

$(document).on('change', '#filter-year-cite', function() {
  var product_class = $('#product-class').val();
  var product_id = $('#product-id').val();
  var year = $(this).val();
  var url = '/citas/ui/' + product_class + '/' + product_id + '?year=' + year
  $.get(url, function(data) {
    $("#cites-workarea").html(data);
  });
})

$(document).on('click', '#add-cite-link', function() {
  var product_class = $(this).data('product-class');
  var product_id = $(this).data('product-id');
  var url = '/citas/nueva/' + product_class + '/' + product_id
  $.get(url, function(data) {
    $('.cites-ui').html(data);
  });
})

$(document).on('click', '.edit-cite', function() {
  var id = $(this).data('cite-id');
  var url = '/citas/editar/' + id
  $.get(url, function(data) {
    $('.cites-ui').html(data);
  });
})

$(document).on('click', '.delete-cite', function() {
  var product_class = $('#product-class').val();
  var product_id = $('#product-id').val();
  var id = $(this).data('cite-id');
  var url = '/citas/elimina/' 
  $.post( url, { 
                 product_class: product_class,
                 product_id: product_id,
     	         id: id, 
               })
    .done(function( data ) {
      var url = '/citas/ui/' + product_class + '/' + product_id
      $.get(url, function(data) {
        $('.cites-ui').html(data);
      });
    })
})

$(document).on('click', '#add-cite', function() {
  var valid = true;
  var product_class = $('#product_class').val();
  var product_id = $('#product_id').val();

  var year = $('#product_cite_year').val();
  var citation_text = $('#product_cite_citation_text').val();

  var title = $('#product_cite_title').val();
  var pub_date = $('#product_cite_published_date').val();
  var authors = $('#product_cite_authors').val();
  var journal_id = $('#product_cite_journal_id').val();
  var volume = $('#product_cite_volume').val();
  var issue = $('#product_cite_issue').val();
  var pages = $('#product_cite_pages').val();
  var doi = $('#product_cite_doi').val();
  var t = $('#ui_cite_type').val();
  var errors = '';

  if (t == 'simple') {

    if (citation_text.length < 10) {
      errors += "Necesitas especificar la cita\n";
      valid = false
    }
  } else {

    if (title.length < 5) {
      errors += "Necesitas especificar el título del artículo\n";
      valid = false
    }

    if (pub_date.length != 10) {
      errors += "Necesitas especificar la fecha de publicación\n";
      valid = false
    }

    if (authors.length < 5) {
      errors += "Necesitas especificar los autores\n";
      valid = false
    }

    if (!(journal_id > 0)) {
      errors += "Necesitas especificar la revista\n";
      valid = false
    }
  }

  if (!valid) {
    alert(errors);
  } else {
  	var url = '/citas/agrega';
     $.post( url, { 
                 ui_cite_type: t,
                 product_class: product_class,
                 product_id: product_id,
                 year: year,
                 citation_text: citation_text,  
     	           title: title, 
                 published_date: pub_date,
                 authors:  authors,
                 journal_id:  journal_id,
                 volume: volume,
                 issue: issue,
                 pages: pages,
                 doi: doi 

               })
    .done(function( data ) {
      var cite_id = data.id;
      var url = '/citas/ui/' + product_class + '/' + product_id
      $.get(url, function(data) {
        $('.cites-ui').html(data);
      });
    })
  }
 
});

$(document).on('click', '#update-cite', function() {
  var valid = true;
  var t = $('#ui_cite_type').val();
  var product_class = $('#product_class').val();
  var product_id = $('#product_id').val();
  
  var year = $('#product_cite_year').val();
  var citation_text = $('#product_cite_citation_text').val();

  var id = $('#cite_id').val();
  var title = $('#product_cite_title').val();
  var pub_date = $('#product_cite_published_date').val();
  var authors = $('#product_cite_authors').val();
  var journal_id = $('#product_cite_journal_id').val();
  var volume = $('#product_cite_volume').val();
  var issue = $('#product_cite_issue').val();
  var pages = $('#product_cite_pages').val();
  var doi = $('#product_cite_doi').val();
  var errors = '';
  if (t == 'simple') {

    if (citation_text.length < 10) {
      errors += "Necesitas especificar la cita\n";
      valid = false
    }
  } else {
    if (title.length < 5) {
      errors += "Necesitas especificar el título del artículo\n";
      valid = false
    }

    if (pub_date.length != 10) {
      errors += "Necesitas especificar la fecha de publicación\n";
      valid = false
    }

    if (authors.length < 5) {
      errors += "Necesitas especificar los autores\n";
      valid = false
    }

    if (!(journal_id > 0)) {
      errors += "Necesitas especificar la revista\n";
      valid = false
    }
  }

  if (!valid) {
    alert(errors);
  } else {
  	var url = '/citas/actualiza';
     $.post( url, { 
     	         id: id,
                ui_cite_type: t,

                 product_class: product_class,
                 product_id: product_id,
                 year: year,
                 citation_text: citation_text, 
     	         title: title, 
                 published_date: pub_date,
                 authors:  authors,
                 journal_id:  journal_id,
                 volume: volume,
                 issue: issue,
                 pages: pages,
                 doi: doi 

               })
    .done(function( data ) {
      var cite_id = data.id;
      var url = '/citas/ui/' + product_class + '/' + product_id
      $.get(url, function(data) {
        $('.cites-ui').html(data);
      });
    })
  }
 
});



var modal;
$(document).on("turbolinks:load", function() {
  modal = Bulma.create('modal', {
    root: document.getElementById('cite-select-journal-div'),
    title: 'Seleccionar revista',
    body: '<div id="search-workarea"><div class="search-controls"><input type="text" placeholder="Nombre de la revista..." id="cite-journal-title-select" class="input"></div><div id="cite-journal-list-select"></div></div>',
  });
});

$(document).on('click', '#cite-select-journal', function() {
  $('#cite-journal-title-select').val('');
  $('#cite-journal-list-select').html('');
  modal.open();
});

$(document).on('keyup', '#cite-journal-title-select', function() {
  var q = $('#cite-journal-title-select').val();
  if (q.length > 4) {
    var url = '/revistas/buscar?t=cites&q=' + q;
    $.get(url, function(data) {
      $('#cite-journal-list-select').html(data);
    });
  }
});


$(document).on('click', '.cite-journal-list-item', function() {
  var journal_id = $(this).data('id');
  $('#product_cite_journal_id').val(journal_id);
  var url = '/revistas/datos/' + journal_id + '?t=cites';
    $.get(url, function(data) {
      $('#cite-selected-journal').html(data);
      modal.close();
    });
});

$(document).on('click', '#cite-add-new-journal', function() {
  var url = '/revistas/nueva/'
    $.get(url, function(data) {
      $('#search-workarea').html(data);
    });
});

$(document).on('click', '#cite-submit-new-journal', function() {
  $.post( "/revistas/agregar-nueva", { name: $('#journal_name').val(), country_id: $('#journal_country_id').val() })
    .done(function( data ) {
      var journal_id = data.id;
      $('#product_cite_journal_id').val(journal_id);
      var url = '/revistas/datos/' + journal_id;
        $.get(url, function(data) {
          $('#selected-journal').html(data);
          modal.close();
        });
    })
});

