$(document).on('change', '.filter-select', function() {
  var status = $('#filter-status').val()
  var year = $('#filter-year').val()
  var url =  $(this).data('url') + '/muestra'
  if (typeof status !== 'undefined') {
   url = url + '/' + status
  }
  //if (year != 'todos') {
    url = url + '/' + year;
  //}
  $('#loading').show();
  window.location = url;
});


$(document).on('click', '.profile-menu a', function() {
  $('#loading').show();
});

$(document).on('change', '.lab-graph-filter-select', function() {
  var lab_id = $('#filter-lab-graph-lab').val()
  var year = $('#filter-lab-graph-year').val()
  var url =  $(this).data('url')
  if (typeof status !== 'undefined') {
   url = url + '/' + lab_id
  }
  //if (year != 'todos') {
    url = url + '/' + year;
  //}
  $('#loading').show();
  window.location = url;
});
