import { SVGGraph } from "calendar-graph";


// window.onload = () => {
// new SVGGraph("#svg-root", data, {
//   startDate: new Date('2021-03-28'),
//   endDate: new Date('2022-03-28')
// });
// }

window.drawLabGraph = function(data, start_date, end_date) {
  new SVGGraph("#svg-root", data, {
  startDate: new Date(start_date),
  endDate: new Date(end_date)
});
}

 window.tooltipInit = function() {
  const tip = document.getElementById("tooltip");
  let elems = document.getElementsByClassName("cg-day");
  const mouseOver = function (e) {
    e = e || window.event;
    const elem = e.target || e.srcElement;
    const rect = elem.getBoundingClientRect();
    const date = elem.getAttribute("data-date");
    const count = elem.getAttribute("data-count");
    const samplesProc =
      count === 1 ? `${date}: ${count} muestra` : `${date}: ${count} muestras`;
    tip.style.display = "block";
    tip.textContent = samplesProc;
    const w = tip.getBoundingClientRect().width;
    tip.style.left = `${rect.left - w / 2 + 6}px`;
    tip.style.top = `${rect.top - 35}px`;
  };
  const mouseOut = function (e) {
    e = e || window.event;
    tip.style.display = "none";
  };
  for (let i = 0; i < elems.length; i++) {
    if (document.body.addEventListener) {
      elems[i].addEventListener("mouseover", mouseOver, false);
      elems[i].addEventListener("mouseout", mouseOut, false);
    } else {
      elems[i].attachEvent("onmouseover", mouseOver);
      elems[i].attachEvent("onmouseout", mouseOut);
    }
  }
}
