import Bulma from '@vizuaalog/bulmajs';

$(document).on('change', '#internal_project_participant_is_internal', function() {
  var val = $(this).val();
  if (val == 0) {
    $(".participante-interno").hide();
    $(".participante-externo").show();
  } else {
    $(".participante-interno").show();
    $(".participante-externo").hide();
  }
})

$(document).on('change', '.mode_a_input', function() {
  var sum_a = 0;
  $( ".mode_a_input" ).each(function() {
    sum_a += parseInt($( this ).val());
  });
  $('#mode_a_total').html(sum_a);
  
})

$(document).on('change', '.mode_b_input', function() {
  var sum_b = 0;
  $( ".mode_b_input" ).each(function() {
    sum_b += parseInt($( this ).val());
  });
  $('#mode_b_total').html(sum_b);
  
})

$(document).on('click', '.show-ip-detail-a', function() {
  var det = $(this).data('detail')
  if ($(this).html() == 'Mostrar detalle') {
    $(this).html('Ocultar detalle')
  } else {
    $(this).html('Mostrar detalle')  
  }
  
  $('#' + det).toggle();
})

$(document).on('click', '.show-ip-detail-b', function() {
  var det = $(this).data('detail')
  if ($(this).html() == 'Mostrar detalle') {
    $(this).html('Ocultar detalle')
  } else {
    $(this).html('Mostrar detalle')  
  }
  
  $('#' + det).toggle();
})

$(document).on('change', '.internal_project_concept_field', function() {
  var val = $(this).val();
  var concept_id = $(this).data('id')
  var concept = $(this).data('concept');
  var url = $(this).data('url');


  $.post( url, { concept_id: concept_id, 
                 value: val,
               })
    .done(function( data ) {
      Bulma.create('notification', {
        isDismissable: true,
        dismissInterval: 4000,
        color: 'success',
        body: 'Partida ' + concept + ' actualizada.',
        parent: document.getElementById('notification-area'),
       }).show();
    })

  
});

$(document).on('change', '#internal_project_mode', function() {
  var mode = $('#internal_project_mode').val();
  if (mode == 1) {
    $(".mode_b").hide();
    $(".mode_a").show();
  } else {
    $(".mode_a").hide();
    $(".mode_b").show();
  }
});

$(document).on('click', '#add-internal-project', function() {
  var user = $(this).data('shortname');
  var url = '/perfiles/' + user + '/convocatorias-de-proyectos-internos';
  var valid = true;
  var name = $('#internal_project_name').val();
  var mode = $('#internal_project_mode').val();
  var internal_project_announcement_id = $('#internal_project_internal_project_announcement_id').val();
  var errors = '';
  if (name.length < 5) {
    errors += "Necesitas especificar el nombre del proyecto\n";
    valid = false
  }

  if (!valid) {
    alert(errors);
  } else {
     $.post( url, { name: name, 
                 mode: mode,
                 internal_project_announcement_id:  internal_project_announcement_id
               })
    .done(function( data ) {
      var internal_project_id = data.id;
      url = url + '/' + internal_project_id + '/editar';
      window.location = url;
    })
  }
 
});



$(document).on('ajax:success', '#new_internal_project_participant', function(data){
  var url = $('#new_internal_project_participant').attr('action')
  $.get(url, function(data) {
    $('#internal-project-participants-ui').html(data);
  });
    
  Bulma.create('notification', {
    isDismissable: true,
    dismissInterval: 4000,
    color: 'success',
    body: 'Participante agregado.',
    parent: document.getElementById('notification-area'),
   }).show();
});


$(document).on('ajax:success', '.delete-internal-project-participant', function(data){
  var url = $('#new_internal_project_participant').attr('action')
  $.get(url, function(data) {
    $('#internal-project-participants-ui').html(data);
  });
    
  Bulma.create('notification', {
    isDismissable: true,
    dismissInterval: 4000,
    color: 'success',
    body: 'Participante eliminado.',
    parent: document.getElementById('notification-area'),
   }).show();
});
