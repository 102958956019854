
$(document).on('change', '#filter-dep-report', function() {
    var dep_id = $('#filter-dep-report').val()
    var year = $('#filter-dep-report-year').val()
    var url =  $(this).data('url')
    var pt_filter = $('#filter-dep-report-type').val()
    url = url + '/' + dep_id + '/' + year + '?pt_filter=' + pt_filter
    $('#loading').show();
    window.location = url;
  });

  $(document).on('change', '#filter-dep-report-type', function() {
    var dep_id = $('#filter-dep-report').val()
    var year = $('#filter-dep-report-year').val()
    var pt_filter = $('#filter-dep-report-type').val()
    var url =  $(this).data('url')
    url = url + '/' + dep_id + '/' + year + '?pt_filter=' + pt_filter
    $('#loading').show();
    window.location = url;
  });