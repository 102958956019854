import Bulma from '@vizuaalog/bulmajs';

window.window_calculate_committee = function calculate_committee() {
  var limit_no_vinc = parseInt($('#limit-no-vinc').data('limit'))
  var limit_vinc = parseInt($('#limit-vinc').data('limit'))
  var est1_no_vinc = 0
  var est1_vinc = 0
  var pres1_no_vinc = 0
  var pres1_vinc = 0
  var limited1_no_vinc = 0
  var limited1_vinc = 0

  var est2_no_vinc = 0
  var est2_vinc = 0
  var pres2_no_vinc = 0
  var pres2_vinc = 0
  var limited2_no_vinc = 0
  var limited2_vinc = 0

  var est3_no_vinc = 0
  var est3_vinc = 0
  var pres3_no_vinc = 0
  var pres3_vinc = 0
  var limited3_no_vinc = 0
  var limited3_vinc = 0
  
  // PRIMER 

  $('.ev1-est-val-no-vinc').each(function(i, obj) {
    est1_no_vinc = est1_no_vinc + parseFloat($(this).val())
  });

  $('.ev1-est-val-vinc').each(function(i, obj) {
    est1_vinc = est1_vinc + parseFloat($(this).val())
  });

  est1_vinc = Math.round(est1_vinc*100)/100
  est1_no_vinc = Math.round(est1_no_vinc*100)/100

  $('.ev1-pres-val-no-vinc').each(function(i, obj) {
    pres1_no_vinc = pres1_no_vinc + parseFloat($(this).val())
  });

  $('.ev1-pres-val-vinc').each(function(i, obj) {
    pres1_vinc = pres1_vinc + parseFloat($(this).val())
  });

  pres1_vinc = Math.round(pres1_vinc*100)/100
  pres1_no_vinc = Math.round(pres1_no_vinc*100)/100


  if (($('#evaluation-type').val() == 1) || ($('#evaluation-type').val() == 2)) {
    if (est1_vinc > limit_vinc) {
      limited1_vinc = limit_vinc
    } else {
      limited1_vinc = est1_vinc
    }

    if (est1_no_vinc > limit_no_vinc) {
      limited1_no_vinc = limit_no_vinc
    } else {
      limited1_no_vinc = est1_no_vinc
    }
  } else {
   limited1_no_vinc = est1_no_vinc
   limited1_vinc = est1_vinc
  }

  $('#ev1-punt-no-vinc').html(pres1_no_vinc.toFixed(2))
  $('#ev1-punt-vinc').html(pres1_vinc.toFixed(2))
  $('#ev1-sum-punt').html((pres1_no_vinc + pres1_vinc).toFixed(2))
  $('#ev1-total-no-vinc').html(limited1_no_vinc.toFixed(2))
  $('#ev1-total-vinc').html(limited1_vinc.toFixed(2))


  var total1 = (limited1_no_vinc + limited1_vinc).toFixed(2)
  if (total1 > 100) {
    total1 = 100
  }
  $('#ev1-sum-total').html(total1)

  // SEGUNDO

  $('.ev2-est-val-no-vinc').each(function(i, obj) {
    est2_no_vinc = est2_no_vinc + parseFloat($(this).val())
  });

  $('.ev2-est-val-vinc').each(function(i, obj) {
    est2_vinc = est2_vinc + parseFloat($(this).val())
  });

  est2_vinc = Math.round(est2_vinc*100)/100
  est2_no_vinc = Math.round(est2_no_vinc*100)/100

  $('.ev2-pres-val-no-vinc').each(function(i, obj) {
    pres2_no_vinc = pres2_no_vinc + parseFloat($(this).val())
  });

  $('.ev2-pres-val-vinc').each(function(i, obj) {
    pres2_vinc = pres2_vinc + parseFloat($(this).val())
  });

  pres2_vinc = Math.round(pres2_vinc*100)/100
  pres2_no_vinc = Math.round(pres2_no_vinc*100)/100


  if (($('#evaluation-type').val() == 1) || ($('#evaluation-type').val() == 2)) {
    if (est2_vinc > limit_vinc) {
      limited2_vinc = limit_vinc
    } else {
      limited2_vinc = est2_vinc
    }

    if (est2_no_vinc > limit_no_vinc) {
      limited2_no_vinc = limit_no_vinc
    } else {
      limited2_no_vinc = est2_no_vinc
    }
  } else {
   limited2_no_vinc = est2_no_vinc
   limited2_vinc = est2_vinc
  }

  $('#ev2-punt-no-vinc').html(pres2_no_vinc.toFixed(2))
  $('#ev2-punt-vinc').html(pres2_vinc.toFixed(2))
  $('#ev2-sum-punt').html((pres2_no_vinc + pres2_vinc).toFixed(2))
  $('#ev2-total-no-vinc').html(limited2_no_vinc.toFixed(2))
  $('#ev2-total-vinc').html(limited2_vinc.toFixed(2))

  var total2 = (limited2_no_vinc + limited2_vinc).toFixed(2)
  if (total2 > 100) {
    total2 = 100
  }
  $('#ev2-sum-total').html(total2)



  // COMITE

  $('.ev3-est-val-no-vinc').each(function(i, obj) {
    est3_no_vinc = est3_no_vinc + parseFloat($(this).val())
  });

  $('.ev3-est-val-vinc').each(function(i, obj) {
    est3_vinc = est3_vinc + parseFloat($(this).val())
  });

  est3_vinc = Math.round(est3_vinc*100)/100
  est3_no_vinc = Math.round(est3_no_vinc*100)/100

  $('.ev3-pres-val-no-vinc').each(function(i, obj) {
    pres3_no_vinc = pres3_no_vinc + parseFloat($(this).val())
  });

  $('.ev3-pres-val-vinc').each(function(i, obj) {
    pres3_vinc = pres3_vinc + parseFloat($(this).val())
  });

  pres3_vinc = Math.round(pres3_vinc*100)/100
  pres3_no_vinc = Math.round(pres3_no_vinc*100)/100


  if (($('#evaluation-type').val() == 1) || ($('#evaluation-type').val() == 2)) {
    if (est3_vinc > limit_vinc) {
      limited3_vinc = limit_vinc
    } else {
      limited3_vinc = est3_vinc
    }

    if (est3_no_vinc > limit_no_vinc) {
      limited3_no_vinc = limit_no_vinc
    } else {
      limited3_no_vinc = est3_no_vinc
    }
  } else {
   limited3_no_vinc = est3_no_vinc
   limited3_vinc = est3_vinc
  }

  $('#ev3-punt-no-vinc').html(pres3_no_vinc.toFixed(2))
  $('#ev3-punt-vinc').html(pres3_vinc.toFixed(2))
  $('#ev3-sum-punt').html((pres3_no_vinc + pres3_vinc).toFixed(2))
  $('#ev3-total-no-vinc').html(limited3_no_vinc.toFixed(2))
  $('#ev3-total-vinc').html(limited3_vinc.toFixed(2))
  var total3 = (limited3_no_vinc + limited3_vinc).toFixed(2)
  if (total3 > 100) {
    total3 = 100
  }
  $('#ev3-sum-total').html(total3)

}



window.window_calculate_ev_est = function calculate_ev_est() {
  var ev_kind = parseInt($('#ev-kind').val())
  var limit_no_vinc = parseInt($('#limit-no-vinc').data('limit'))
  var limit_vinc = parseInt($('#limit-vinc').data('limit'))
  var est_no_vinc = 0
  var est_vinc = 0
  var pres_no_vinc = 0
  var pres_vinc = 0
  var limited_no_vinc = 0
  var limited_vinc = 0
  
  $('.ev-est-val-no-vinc').each(function(i, obj) {
    est_no_vinc = est_no_vinc + parseFloat($(this).val())
  });

  $('.ev-est-val-vinc').each(function(i, obj) {
    est_vinc = est_vinc + parseFloat($(this).val())
  });

  est_vinc = Math.round(est_vinc*100)/100
  est_no_vinc = Math.round(est_no_vinc*100)/100



  $('.ev-pres-val-no-vinc').each(function(i, obj) {
    pres_no_vinc = pres_no_vinc + parseFloat($(this).val())
  });

  $('.ev-pres-val-vinc').each(function(i, obj) {
    pres_vinc = pres_vinc + parseFloat($(this).val())
  });

  pres_vinc = Math.round(pres_vinc*100)/100
  pres_no_vinc = Math.round(pres_no_vinc*100)/100


  if (($('#evaluation-type').val() == 1) || ($('#evaluation-type').val() == 2)) {
    if (est_vinc > limit_vinc) {
      limited_vinc = limit_vinc
    } else {
      limited_vinc = est_vinc
    }

    if (est_no_vinc > limit_no_vinc) {
      limited_no_vinc = limit_no_vinc
    } else {
      limited_no_vinc = est_no_vinc
    }
  } else {
   limited_no_vinc = est_no_vinc
   limited_vinc = est_vinc
  }



  $('#ev' + ev_kind + '-punt-no-vinc').html(pres_no_vinc.toFixed(2))
  $('#ev' + ev_kind + '-punt-vinc').html(pres_vinc.toFixed(2))
  $('#ev' + ev_kind + '-sum-punt').html((pres_no_vinc + pres_vinc).toFixed(2))

  $('#ev' + ev_kind + '-total-no-vinc').html(limited_no_vinc.toFixed(2))
  $('#ev' + ev_kind + '-total-vinc').html(limited_vinc.toFixed(2))

  var total_ev = (limited_no_vinc + limited_vinc).toFixed(2)
  if (total_ev > 100) {
    total_ev = 100
  }
  $('#ev' + ev_kind + '-sum-total').html(total_ev)

}



window.window_calculate_evaluation = function calculate_evaluation() {


  var limit_no_vinc = parseInt($('#limit-no-vinc').data('limit'))
  var limit_vinc = parseInt($('#limit-vinc').data('limit'))
  var no_vinc = 0
  var vinc = 0
  var limited_no_vinc = 0
  var limited_vinc = 0
  var sum_punt = 0

  // Sumar no vinc
  

  $('.sug-val-no-vinc').each(function(i, obj) {
    no_vinc = no_vinc + parseFloat($(this).val())
    console.log($(this).data('code') + ': ' + parseFloat($(this).val()))
  });

  // Sumar vinc
  $('.sug-val-vinc').each(function(i, obj) {
    vinc = vinc + parseFloat($(this).val())
    console.log($(this).data('code') + ': ' + parseFloat($(this).val()))
  });

 
  vinc = Math.round(vinc*100)/100
  no_vinc = Math.round(no_vinc*100)/100

  if (($('#evaluation-type').val() == 1) || ($('#evaluation-type').val() == 2)) {
    if (vinc > limit_vinc) {
      limited_vinc = limit_vinc
    } else {
    	limited_vinc = vinc
    }

    if (no_vinc > limit_no_vinc) {
      limited_no_vinc = limit_no_vinc
    } else {
    	limited_no_vinc = no_vinc
    }
  } else {
    no_vinc += vinc
    vinc = 0
    if (no_vinc > 100) {
      limited_no_vinc = 100
    } else {
      limited_no_vinc = no_vinc
    }
    limited_vinc = vinc
  }



  $('#punt-no-vinc').html(no_vinc.toFixed(2))
  $('#punt-vinc').html(vinc.toFixed(2))
  sum_punt = no_vinc + vinc
  $('#sum-punt').html(sum_punt.toFixed(2))

  $('#total-no-vinc').html(limited_no_vinc.toFixed(2))
  $('#total-vinc').html(limited_vinc.toFixed(2))


  var total_ev = limited_no_vinc + limited_vinc
  if ((total_ev < 100) && (sum_punt >= 150)) {
    var puntos_extra = 10
    if (sum_punt >= 220) {
      puntos_extra = 30
    } else if (sum_punt >= 190) {
      puntos_extra = 20
    }
    console.log("Puntos extra: " + puntos_extra)
    total_ev += puntos_extra
    console.log("Total: " + total_ev)
    $('#sum-total-head').html('Total<br>+' + puntos_extra +' puntos extra')
  }
  if (total_ev > 100) {
    total_ev = 100
  }
  
  $('#sum-total').html(total_ev.toFixed(2))

}

$(document).on('change', '#criteria-select', function() {
  var person_id = $(this).data('current-user-id')

  if ($(this).val() == 1) {
    $('#limit-no-vinc').data('limit', $('#criteria-1-no-vinc').val())
    $('#limit-no-vinc').html($('#criteria-1-no-vinc').val())
    $('#limit-vinc').data('limit', $('#criteria-1-vinc').val())
    $('#limit-vinc').html($('#criteria-1-vinc').val())
  } else {
  	$('#limit-no-vinc').data('limit', $('#criteria-2-no-vinc').val())
    $('#limit-no-vinc').html($('#criteria-2-no-vinc').val())
    $('#limit-vinc').data('limit', $('#criteria-2-vinc').val())
    $('#limit-vinc').html($('#criteria-2-vinc').val())
  }
  
  window_calculate_evaluation()

  $.post( "/evaluacion/cambia-criterio", { person_id: person_id, criteria: $(this).val() })
    .done(function( data ) {
      Bulma.create('notification', {
      	isDismissable: true,
      	dismissInterval: 4000,
      	color: 'success',
        body: 'Criterio actualizado.',
        parent: document.getElementById('notification-area'),
       }).show();
    });
  
});

$(document).on('change', '.sug-val', function() {
  window_calculate_evaluation()
});

$(document).on('change', '.ev-est-val', function() {
  window_calculate_ev_est()
});

$(document).on('change', '.ev-pres-val', function() {
  window_calculate_ev_est()
});

$(document).on('change', '#ev-change-participant', function() {
  window.location = '/evaluacion-estimulos/' + $(this).val() 
});

$(document).on('change', '#committee-change-participant', function() {
  window.location = '/comite-estimulos/' + $(this).val() 
});

$(document).on('change', '#results-change-participant', function() {
  window.location = '/resultados-estimulos/' + $(this).val() 
});

$(document).on('change', '#results-pre-change-participant', function() {
  window.location = '/resultados-estimulos-pre/' + $(this).val() 
});
